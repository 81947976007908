// eslint-disable-next-line no-unused-vars
import { CurrentUserState } from '../types/CurrentUserState'

const currentUser = (state : CurrentUserState = { authenticated: false, authentificationProgressStatus: false, authentificationError: false }, action: {googleUser?: {googleId: string, email: string, name: string, givenName: string, familyName: string,
  profilePicture: string, idToken: string, token: string}, type: String, username: String, token: String, timeoutReference?: ReturnType<typeof setTimeout>}) => {
  switch (action.type) {
    case 'USER_CONNECTION':
      return Object.assign({}, state, { authenticated: true, username: action.username, token: action.token })
    case 'USER_DISCONNECTION':
      if (state.jwtRenewalTimeoutReference) {
        clearTimeout(state.jwtRenewalTimeoutReference)
      }
      return Object.assign({}, { authenticated: false })
    case 'USER_REQUEST_CREDENTIAL_CHECK':
      return Object.assign({}, state, { authentificationProgressStatus: true, authentificationError: false })
    case 'USER_CREDENTIAL_CHECKED':
      return Object.assign({}, state, { authentificationProgressStatus: false, authentificationError: false })
    case 'USER_CREDENTIAL_ERROR':
      return Object.assign({}, state, { authentificationProgressStatus: false, authentificationError: true })
    case 'USER_RECEIVED_USER_INFO_FROM_GOOGLE':
      return Object.assign({}, state, { googleUserInfo: action.googleUser })
    case 'USER_OAUTH_ERROR':
      return Object.assign({}, state, { authentificationProgressStatus: false, authentificationError: true })
    case 'USER_ID_TOKEN_RECEIVED':
      return Object.assign({}, state, { authentificationProgressStatus: false, authentificationError: false, token: action.token, authenticated: true })
    case 'USER_TIMEOUT_REFERENCE_CREATED':
      return Object.assign({}, state, { jwtRenewalTimeoutReference: action.timeoutReference })
    default:
      return state
  }
}

export default currentUser
