/* eslint-disable no-unused-vars */
import { Mllp } from '../types/MllpState'

const probes = (state: Mllp = {}, action: {type: String, mllpResult?: {status:string, statusText:string, raw:string}}) => {
  switch (action.type) {
    case 'MLLP_MESSAGE_POST_STARTED':
      return Object.assign({}, state, { refreshStatus: 'IN_PROGRESS' })
    case 'MLLP_MESSAGE_POST_ENDED':
      return Object.assign({}, state, { refreshStatus: 'DONE', mllpResult: action.mllpResult })
    default:
      return state
  }
}

export default probes
