import React from 'react'
import IPage from '../iPage'
import ICard from '../iCard'

const Home = () => {
  return (
    <IPage title="Bienvenue dans Unicorn">
      <ICard title="Les liens utiles">
        <dl className="row">
          <dt className="col-sm-4">Notre outil RH</dt>
          <dd className="col-sm-8"><a target="_blank" rel="noopener noreferrer" href='https://hoppen-care.kelio.io/'>https://hoppen-care.kelio.io/</a></dd>
          <dt className="col-sm-4">Intranet / Sharepoint</dt>
          <dd className="col-sm-8"><a target="_blank" rel="noopener noreferrer" href='https://telecomsante.sharepoint.com/'>https://telecomsante.sharepoint.com/</a></dd>
          <dt className="col-sm-4">Boite à outils</dt>
          <dd className="col-sm-8">Copier/coller sécurisé et auto hébergé : <a target="_blank" rel="noopener noreferrer" href='https://paste.exolis.fr'>https://paste.exolis.fr/</a></dd>
          <dd className="col-sm-8 offset-sm-4">Suivi des tags : <a target="_blank" rel="noopener noreferrer" href='https://release-tag.exolis.fr'>https://release-tag.exolis.fr/</a></dd>
          <dd className="col-sm-8 offset-sm-4">Suivi des builds (MacGyver) : <a target="_blank" rel="noopener noreferrer" href='https://macgyver.exolis.fr:3443/'>https://macgyver.exolis.fr:3443/</a></dd>
          <dd className="col-sm-8 offset-sm-4">Tests interop fichier sortants : <a target="_blank" rel="noopener noreferrer" href='https://interop-test.exolis.fr/'>https://interop-test.exolis.fr/</a></dd>
          <dt className="col-sm-4">Support/Indus</dt>
          <dd className="col-sm-8">JIRA (ENGSUP) : <a target="_blank" rel="noopener noreferrer" href='https://hoppen-care.atlassian.net/jira/servicedesk/projects/ENGSUP'>https://hoppen-care.atlassian.net/jira/servicedesk/projects/ENGSUP</a></dd>
          <dd className="col-sm-8 offset-sm-4">Wiki INDUS : <a target="_blank" rel="noopener noreferrer" href='https://wiki-rds.hoppen.care/books/portail-patient-indus'>https://wiki-rds.hoppen.care/books/portail-patient-indus</a></dd>
          <dt className="col-sm-4">Dev</dt>
          <dd className="col-sm-8">JIRA (ENG) : <a target="_blank" rel="noopener noreferrer" href='https://hoppen-care.atlassian.net/jira/servicedesk/projects/ENG'>https://hoppen-care.atlassian.net/jira/servicedesk/projects/ENG</a></dd>
          <dt className="col-sm-4">Pour les démos</dt>
          <dd className="col-sm-8">Patient : <a target="_blank" rel="noopener noreferrer" href='https://patients-demo.exolis.fr/'>https://patients-demo.exolis.fr/</a></dd>
          <dd className="col-sm-8 offset-sm-4">Soignant : <a target="_blank" rel="noopener noreferrer" href='https://suivi.exolis.fr/'>https://suivi.exolis.fr/</a></dd>
        </dl>
      </ICard>
    </IPage>
  )
}

export default Home
