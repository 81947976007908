import React from 'react'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
import { userSigninAcceptedByGoogleOAuth, userGoogleOAuthError } from '../../actions'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../types/IThunkDispatch'
import { Redirect } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import UseGoogleLoginButton from '../useGoogleLoginButton'
import './Login.css'
import Logo from '../logo'

const mapDispatchToProps = (dispatch: IThunkDispatch) => {
  return {
    onGoogleOAuthSuccess: (googleUser: {googleId: string, email: string, name: string, givenName: string, familyName: string,
      profilePicture: string, idToken: string, token: string}) => {
      dispatch(userSigninAcceptedByGoogleOAuth(googleUser))
    },
    onGoogleOAuthError: () => {
      dispatch(userGoogleOAuthError())
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentUser
})

const connector = connect(mapStateToProps, mapDispatchToProps)

class Login extends React.Component<ConnectedProps<typeof connector>> {
  constructor (props: ConnectedProps<typeof connector>) {
    super(props)
    this.handleLoad = this.handleLoad.bind(this)
  }

  componentDidMount () {
    window.addEventListener('load', this.handleLoad)
  }

  componenentWillUnmount () {
    window.removeEventListener('load', this.handleLoad)
  }

  handleLoad () {
    window.removeEventListener('load', this.handleLoad)
  }

  render () {
    if (!this.props.currentUser.authenticated) {
      return (
        <div className="login-page">
          <div className="login-box">
            <div className="login-logo">
              <Logo />
            </div>
            <div className="card">
              <div className="card-body login-card-body">
                <p className="login-box-msg">Connectez-vous pour démarrer</p>
                <div className="social-auth-links text-center mb-3">

                  <UseGoogleLoginButton onAuthSuccess={this.props.onGoogleOAuthSuccess} onAuthError={this.props.onGoogleOAuthError} block={true} colorStyle="primary" text="Se connecter avec Google 🚀" />

                  <div className="row">
                    <div className="col-12 login-error-message">
                      {this.props.currentUser.authentificationError ? 'Authentification invalide (vérifiez le domaine)' : '\u00A0'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <Redirect to = {{ pathname: '/' }} />
      )
    }
  }
}

export default connector(Login)
