import React from 'react'
import IPage from '../iPage'
import ICard from '../iCard'
// eslint-disable-next-line no-unused-vars
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../types/RootState'
// eslint-disable-next-line no-unused-vars
import { IThunkDispatch } from '../../types/IThunkDispatch'
import { mllpSendMessage } from '../../actions'
import { required } from '../../utils/fieldValidation'
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import IButton from '../iButton'
import IInputSmall from '../iInputSmall'
import ITextarea from '../iTextarea'
// eslint-disable-next-line no-unused-vars
import { Mllp as MllpState } from '../../types/MllpState'
import './mllp.css'

const mapDispatchToProps = (dispatch: IThunkDispatch, ownProps: any) => {
  return {
    sendMllpMessage: (values: {message: string, adresse: string}) => {
      dispatch(mllpSendMessage(values.adresse, values.message))
      // ownProps.reset()
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  mllp: ((state as any).mllp as MllpState)
})

const connector = connect(mapStateToProps, mapDispatchToProps)

const Mllp = (props:ConnectedProps<typeof connector> & InjectedFormProps) => {
  return (
    <IPage title="MLLP">
      <ICard title="Message HL7">
        <p>Pour voir les fichiers <em>générés (sortants)</em> par nos serveurs, c'est ici : <a target="_blank" rel="noopener noreferrer" href='https://interop-test.exolis.fr/'>https://interop-test.exolis.fr/</a> <br />
        Pour en envoyer, utilisez les champs ci-dessous : </p>
        <form method="post" onSubmit={props.handleSubmit((values:any) => props.sendMllpMessage(values))}>
          <Field name="message" component={ITextarea} className="form-control" placeholder="Message *" validate={[required]} rows="10" />
          <Field name="adresse" component={IInputSmall} type="text" className="form-control" placeholder="Adresse *" validate={[required]}/>
          <div>Adresses utilisables (Exemples pour la 7.<b>35</b>) :
            <ul>
              <li>SIU : patients-7.35.exolis.fr:181<b>35</b></li>
              <li>ADT: patients-7.35.exolis.fr:182<b>35</b></li>
              <li>MDM : patients-7.35.exolis.fr:183<b>35</b></li>
              <li>ORU (document) : patients-7.35.exolis.fr:184<b>35</b></li>
              <li>ORM : patients-7.35.exolis.fr:185<b>35</b></li>
              <li>ORU (report) : patients-7.35.exolis.fr:186<b>35</b></li>
            </ul>
          </div>
          <IButton text="Envoyer" colorStyle="primary" inProgress={!!((props.mllp && props.mllp.refreshStatus && props.mllp.refreshStatus === 'IN_PROGRESS'))} type="submit"/>
        </form>
        {(props.mllp.refreshStatus && props.mllp.refreshStatus === 'DONE' && props.mllp.mllpResult) && (
          <div className='mllp-result-block'>
            <p>Réponse du webservice : <span className={`mllp-result  ${(props.mllp.mllpResult.status === 'success') ? 'result-ok' : 'result-error'}`}>{props.mllp.mllpResult.statusText}</span> </p>
            <code className='raw-mllp-result'>{props.mllp.mllpResult.raw}</code>
          </div>
        )
        }
      </ICard>
    </IPage>
  )
}

export default reduxForm({ form: 'mllpForm' })(connector(Mllp))
