import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './utils/MyStore'
import { saveState } from './utils/localStorageState'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { userRenewToken } from './actions'

store.subscribe(() => {
  saveState({ currentUser: store.getState().currentUser })
})

userRenewToken(store.dispatch)

library.add(fab, far, fas)

ReactDOM.render(<Provider store={store}><GoogleOAuthProvider clientId="107705709042-l3sgv0s6eg9eljtj2lt7qnpns3lhfqvn.apps.googleusercontent.com"><App /></GoogleOAuthProvider></Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
