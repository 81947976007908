import React from 'react'
import { useGoogleLogin, TokenResponse } from '@react-oauth/google'
import axios from 'axios'

interface UseGoogleLoginButtonProps {
    text: String
    onAuthSuccess: (googleUser: {googleId: string, email: string, name: string, givenName: string, familyName: string,
        profilePicture: string, idToken: string, token: string}) => void,
    onAuthError: (errorResponse:TokenResponse) => void,
    block?: boolean,
    colorStyle?:string
}

function UseGoogleLoginButton (props: UseGoogleLoginButtonProps) {
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const rawResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`
          }
        }
      )

      // eslint-disable-next-line camelcase
      const rawUserInfo:{sub:string, email:string, name:string, given_name:string, family_name:string, picture:string} = rawResponse.data

      props.onAuthSuccess({ googleId: rawUserInfo.sub, email: rawUserInfo.email, name: rawUserInfo.name, givenName: rawUserInfo.given_name, familyName: rawUserInfo.family_name, profilePicture: rawUserInfo.picture, idToken: tokenResponse.access_token, token: tokenResponse.access_token })
    },
    onError: errorResponse => props.onAuthError
  })

  return (
    <button type="button" onClick={() => googleLogin()} className={'iButton btn ' + (props.block ? 'btn-block ' : '') + 'btn-' + props.colorStyle}>{props.text}</button>
  )
}

export default UseGoogleLoginButton
